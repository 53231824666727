@import './index';

.table-size(@size, @padding-vertical, @padding-horizontal) {
  .@{table-prefix-cls}.@{table-prefix-cls}-@{size} {
    .@{table-prefix-cls}-title,
    .@{table-prefix-cls}-footer,
    .@{table-prefix-cls}-thead > tr > th,
    .@{table-prefix-cls}-tbody > tr > td,
    tfoot > tr > th,
    tfoot > tr > td {
      padding: @padding-vertical @padding-horizontal;
    }

    .@{table-prefix-cls}-thead {
      th.@{table-prefix-cls}-column-has-sorters {
        padding: 0;
      }

      .@{table-prefix-cls}-filter-column {
        margin: -@padding-vertical -@padding-horizontal;
      }

      .@{table-prefix-cls}-filter-column-title {
        padding: @padding-vertical 2.3em @padding-vertical @padding-horizontal;
      }

      .@{table-prefix-cls}-column-sorters {
        padding: @padding-vertical @padding-horizontal;
      }
    }

    .@{table-prefix-cls}-expanded-row-fixed {
      margin: -@padding-vertical -@padding-horizontal;
    }
  }
}

// ================================================================
// =                            Middle                            =
// ================================================================
.table-size(~'middle', @table-padding-vertical-md, @table-padding-horizontal-md);

// ================================================================
// =                            Small                             =
// ================================================================
.table-size(~'small', @table-padding-vertical-sm, @table-padding-horizontal-sm);

.@{table-prefix-cls}-small {
  .@{table-prefix-cls}-thead > tr > th {
    background-color: @table-header-bg-sm;
  }
}
